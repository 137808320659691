import axios from 'axios'
import { method } from 'lodash';

const url = process.env.VUE_APP_API_PATH + 'v1/payments';
const broker_url = process.env.VUE_APP_API_PATH + 'v1/broker-payments';

export default {
    state: {
        payment_list: [],
        broker_payment_list: []
    },
    getters: {
        payment_list: state => state.payment_list,
        broker_payment_list: state => state.broker_payment_list,
    },
    mutations: {
        SET_PAYMENT_LIST (state, items) {
            state.payment_list = items;
        },
        SET_BROKER_PAYMENT_LIST (state, items) {
            state.broker_payment_list = items;
        }
    },
    actions: {
        fetchPayments({commit,dispatch},data) {
            return new Promise((resolve, reject) => {
                axios.get(url,{params:{...data}}).then(res => {
                    if(res.data && typeof res.data === 'object') {
                        commit('SET_PAYMENT_LIST',res.data)
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                    reject(err)
                });
            })
        },
        fetchBrokerPayments({commit,dispatch},data) {
            return new Promise((resolve, reject) => {
                axios.get(broker_url,{params:{...data}}).then(res => {
                    if(res.data && typeof res.data === 'object') {
                        commit('SET_BROKER_PAYMENT_LIST',res.data)
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                    reject(err)
                });
            })
        },
    },
}
