// import Vue from 'vue'
// import Vuex from 'vuex'
import auth from './auth'
import info from './info'
import category from './category'
import booking from './booking'
import common from './common'
import payments from './payments'
import clients from './clients'
import users from './users'
import companies from './companies'
import terms from './terms'
import todo from './todo'
import reports from './reports'
import { createStore } from 'vuex';

export default createStore({
  state: {
    error: null,
    errors: []
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = error;
    },
    ADD_ERROR(state, error) {
      state.errors.push(error);
    },
    CLEAR_ERROR(state) {
      state.error = null
    },
    CLEAR_ERRORS(state) {
      state.errors = []
    }
  },
  getters: {
    error: state => state.error,
    errors: state => state.errors
  },
  modules: {
    auth, info, category, booking, common, payments, clients, terms, companies, users, todo, reports
  }
})
