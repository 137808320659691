import axios from 'axios'
const url = process.env.VUE_APP_API_PATH + 'v1/users';
import _ from "lodash";
export default {
    state: {
        someuser: null,
        users: null,
        users_emails: null,
        users_list: null,
        roles: null
    },
    getters: {
        users: state => state.users,
        users_emails: state => state.users_emails,
        someuser: state => state.someuser,
        roles: state => state.roles,
    },
    mutations: {

        SET_SOMEUSER(state, user) {
            state.someuser = user;
        },
        SET_USERS(state, users) {
            state.users = users;
        },
        SET_USERS_EMAILS(state, emails) {
            state.users_emails = emails;
        },
        SET_USERS_LIST(state, users) {
            state.users_list = users;
        },
        SET_USERS_ROlES(state, roles) {
            state.roles = roles;
        },

    },
    actions: {
        fetchAllUsers({commit,dispatch},data) {
            return new Promise((resolve, reject) => {
                axios.get(url,{params:{...data}}).then(res => {
                if(res.data && typeof res.data === 'object') {
                    commit('SET_USERS_LIST',res.data)
                }
                resolve(res.data);
            }).catch(err => {
                commit('SET_ERROR',err);
            });
        })
        },
        fetchUsers({commit,dispatch},search) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_PATH + 'v1/search/?class=user&field=name,surname&select=name,surname&search='+search) .then(res => {
                    const users = [];
                    if(typeof res.data === 'object') {
                        res.data.forEach(it => {
                            if(it.name || it.surname) {
                                const name = it.name + " " + it.surname;
                                users.push(name);
                            }
                        })
                    }
                    commit('SET_USERS',users)
                    resolve();
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        fetchUsersEmails({commit,dispatch},search) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_PATH + 'v1/search/?class=user&field=email&search='+search) .then(res => {
                    const emails = [];
                    if(typeof res.data === 'object') {
                        res.data.forEach(it => {
                            if(it['email']) {
                                emails.push(it['email']);
                            }
                        })
                    }
                    commit('SET_USERS_EMAILS',emails)
                    resolve();
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        fetchSomeUser({commit,dispatch},id) {
            return new Promise((resolve, reject) => {
                axios.get(url + '/' + id).then(res => {
                    if(res.data && typeof res.data === 'object') {
                        commit('SET_SOMEUSER',res.data)
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            });
        },
        deleteUser({commit},id) {
            return new Promise((resolve, reject) => {
                if(!id) {
                    reject("no id provided");
                }
                axios.delete(url + '/' + id) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        storeUser({commit,dispatch},data) {
            return new Promise((resolve, reject) => {
                axios.post(url,data) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            });
        },
        updateUser({commit,dispatch},data) {
            if(!data.id) {
                commit('SET_ERROR',"No user id provided");
                return false;
            }
            return new Promise((resolve, reject) => {
                axios.put(url + '/' + data.id ,data) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            });
        },
        fetchRoles({commit,dispatch}) {
            return new Promise((resolve, reject) => {
                axios.get(url + '/roles').then(res => {
                    if(res.data && typeof res.data === 'object') {
                        commit('SET_USERS_ROlES',res.data)
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                });
            })
        },
        verifyUserEmail({commit,dispatch},user_id) {
            return new Promise((resolve, reject) => {
                axios.post(url + '/verify-email/' + user_id) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            });
        },

    }
}
