export const menu = [
    {
        title: "SAILING SCHOOL",
        link: "",
        order: "",
        header: 0,
        linkto: "sailing school",
        children: {
            submenuName: "sailing school",
            list: [
                {
                    title: "SAILING SCHOOL",
                    link: "",
                    header: 1,
                    children: {}
                },
                {
                    title: "Sailing school main page",
                    link: "/en/sailing-school/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Yacht training",
                    link: "",
                    linkto: "yacht training",
                    header: 0,
                    children: {
                        submenuName: "yacht training",
                        list: [
                            {
                                title: "All practice",
                                link: "",
                                header: 1,
                                children: {}
                            },
                            {
                                title: "Competent crew",
                                link: "/en/sailing-school/practice/crew/",
                                header: 0,
                                children: {}
                            },
                            {
                                title: "Bareboat skipper",
                                link:
                                    "/en/sailing-school/practice/skipper-sail/",
                                header: 0,
                                children: {}
                            },
                            {
                                title: "Blue water sailing practice",
                                link:
                                    "/en/sailing-school/practice/blue-water-sailing/",
                                header: 0,
                                children: {}
                            }
                        ]
                    }
                },
                {
                  title: "Seminars / Webinars",
                  link: "",
                  linkto: "seminars",
                  header: 0,
                  children: {
                    submenuName: "seminars",
                    list: [
                      {
                          title: "All seminars",
                          link: "",
                          header: 1,
                          children: {}
                      },
                      {
                          title: "Marine weather forecasting",
                          link:
                              "/en/sailing-school/shore-based/weather-forecast/",
                          header: 0,
                          children: {}
                      },
                      {
                          title: "Heavy weather tactics",
                          link:
                              "/en/sailing-school/shore-based/heavy-weather/",
                          header: 0,
                          children: {}
                      },
                      {
                          title: "Sailboat docking",
                          link: "/en/sailing-school/shore-based/docking/",
                          header: 0,
                          children: {}
                      }
                  ]
                    },
                },
                {
                  title: "Tests",
                  link: "",
                  linkto: 'tests',
                  header: 0,
                  children: {
                    submenuName: 'tests',
                    list: [
                      {
                          title: "All tests",
                          link: "/en/sailing-school/test/",
                          header: 0,
                          children: {}
                      }
                    ]
                  },
                },
                {
                    title: "Books",
                    link: "/en/shop/",
                    header: 0,
                    children: {}
                },
                {
                    title: "FAQ",
                    link: "/en/sailing-school/answers/",
                    header: 0,
                    children: {}
                }
            ]
        }
    },
    {
        title: "CABIN CHARTERS",
        link: "",
        order: "",
        header: 0,
        linkto: "cabin charters",
        children: {
            submenuName: "cabin charters",
            list: [
                {
                    title: "Cabin Charters",
                    header: 1,
                    link: "",
                    children: {}
                },
                {
                    title: "cabin charters main page",
                    header: 0,
                    link: "/en/cabin-charters/",
                    children: {}
                },
                {
                    title: "Countries",
                    header: 0,
                    link: "",
                    children: {}
                },
                {
                    title: "Regions",
                    header: 0,
                    link: "",
                    children: {}
                },
                {
                    title: "Sailing races",
                    header: 0,
                    link: "/en/cabin-charters/sailing-races/",
                    children: {}
                },
                {
                    title: "Sailing vacations",
                    header: 0,
                    link: "/en/cabin-charters/yacht-cruises/",
                    children: {}
                },
                {
                    title: "Booking conditions",
                    header: 0,
                    link: "/en/cabin-charters/terms-conditions/",
                    children: {}
                },
                {
                    title: "FAQ",
                    header: 0,
                    link: "/en/cabin-charters/questions-answers/",
                    children: {}
                }
            ]
        }
    },
    {
        title: "YACHT CHARTERS",
        link: "",
        linkto: "yacht charters",
        order: "",
        header: 0,
        children: {
            submenuName: "yacht charters",
            list: [
                {
                    title: "YACHT CHARTERS",
                    link: "",
                    header: 1,
                    children: {}
                },
                {
                    title: "yacht charters main page",
                    link: "/en/yacht-charter",
                    header: 0,
                    children: {}
                },
                {
                    title: "Yacht types",
                    link: "",
                    header: 0,
                  linkto: 'yacht types',
                  children: {
                    submenuName: 'yacht types',
                    list: [
                      {
                          title: "Sail yachts",
                          link: "/en/yacht-charter/sailing-yacht/",
                          header: 0,
                          children: {}
                      },
                      {
                          title: "Catamarans",
                          link: "/en/yacht-charter/catamaran-charter/",
                          header: 0,
                          children: {}
                      },
                      {
                          title: "Powerboats",
                          link: "/en/yacht-charter/powerboat-charter/",
                          header: 0,
                          children: {}
                      },
                      {
                          title: "Trimarans",
                          link: "/en/yacht-charter/trimaran-charter/",
                          header: 0,
                          children: {}
                      },
                      {
                          title: "Gulets",
                          link: "/en/yacht-charter/gulet-charter/",
                          header: 0,
                          children: {}
                      },
                      {
                          title: "Houseboats",
                          link: "/en/yacht-charter/houseboat/",
                          header: 0,
                          children: {}
                      }
                  ]
                  },
                },
                {
                    title: "Countries",
                    link: "",
                    header: 0,
                    children: {}
                },
                {
                    title: "Regions",
                    link: "",
                    header: 0,
                    children: {}
                },
                {
                    title: "Booking conditions",
                    link: "/en/yacht-charter/terms-conditions/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Question - Answer",
                    link: "/en/yacht-charter/questions-answers/",
                    header: 0,
                    children: {}
                }
            ]
        }
    },
    {
        title: "ABOUT US",
        link: "",
        linkto: "about us",
        order: "",
        header: 0,
        children: {
            submenuName: "about us",
            list: [
                {
                    title: "ABOUT COMPANY",
                    link: "",
                    header: 1,
                    children: {}
                },
                {
                    title: "about company main page",
                    link: "/en/about-us/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Team",
                    link: "/en/about-us/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Company",
                    link: "/en/company/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Career",
                    link: "/en/career/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Press",
                    link: "/en/press/",
                    header: 0,
                    children: {}
                },
                {
                    title: "News",
                    link: "/en/news/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Reviews",
                    link: "/en/reviews/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Partners",
                    link: "/en/partners/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Contacts",
                    link: "/en/contacts/",
                    header: 0,
                    children: {}
                }
            ]
        }
    },
    {
        title: "LEGAL",
        link: "",
        linkto: "legal",
        order: "",
        header: 0,
        children: {
            submenuName: "legal",
            list: [
                {
                    title: "Legal",
                    link: "",
                    header: 1,
                    children: {}
                },
                {
                    title: "Terms of use",
                    link: "/en/terms-of-use/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Privacy policy",
                    link: "/en/privacy/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Cookies",
                    link: "/en/cookies/",
                    header: 0,
                    children: {}
                }
            ]
        }
    },
    {
        title: "CHARTER REVIEWS",
        link: "/en/reviews",
        order: "",
        header: 0,
        children: {}
    },
    {
        title: "Travel insurance",
        link: "/en/insurance/",
        order: "",
        header: 0,
        children: {}
    },
    {
        title: "LIST YOUR BOAT",
        link: "",
        linkto: "list your boat",
        order: "",
        header: 0,
        children: {
            submenuName: "list your boat",
            list: [
                {
                    title: "Become a partner",
                    link: "",
                    header: 1,
                    children: {}
                },
                {
                    title: "List your boat",
                    link: "/en/b2b/listing/",
                    header: 0,
                    children: {}
                },
                {
                    title: "Terms & Conditions",
                    link: "/en/b2b/terms-conditions/",
                    header: 0,
                    children: {}
                }
            ]
        }
    }
];
