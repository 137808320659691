<template>
    <div>
        <component :is="layout">
            <router-view :key="$route.fullPath"/>
        </component>
    </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
import ClientLayout from "@/layouts/ClientLayout";
import PrintLayout from "@/layouts/PrintLayout";

export default {
    name: "App",
    computed: {
        layout() {
            return this.$role('client') && this.$route.name === 'home' ? 'client-layout': (this.$route.meta.layout || "empty") + "-layout";
        },
    },
    components: {
        EmptyLayout,
        MainLayout,
      ClientLayout,
      PrintLayout
    },
    async mounted() {
        // if(this.$store.getters.user?.id && this.$store.getters.user?.client && this.$role('client')) {
        //    await this.$store.dispatch('fetchClient', this.user.client.id);
        // }
        let currencies = this.$store.state.info.currencies;
        if (localStorage.getItem("currencies") !== null) {
            currencies = JSON.parse(localStorage.getItem("currencies"));
            this.$store.commit('SET_CURRENCIES',currencies)
        }
        if(!currencies || !Object.keys(currencies).length) {
            currencies = await this.$store.dispatch('fetchCur');
        } else {
            this.$store.commit('SET_CURRENCIES',currencies)
        }

        let menu_data = this.$store.state.side_menu_data;
        if(!menu_data || !Object.keys(menu_data).length) {
            await this.$store.dispatch('fetchSideMenuData');
        }
    }
};
</script>
<style lang="scss">
@import "assets/index.scss";
</style>
