<template>
    <aside
        class="sidenav app-sidenav"
        :class="{ open: modelValue }"
    >
        <ul>
            <li v-if="!isClient && !isGuest">
                <a
                    href="#"
                    class="waves-effect waves-orange pointer add"
                    @click.prevent="newBooking"
                >
                    <i class="material-icons">add</i>
                    New booking
                </a>
            </li>
            <li v-for="link in links" :key="link.url">
                <router-link
                    :to="link.url"
                    active-class="active"
                    :title="link.title"
                    class="sidenav__link"
                    @click="handleClick"
                    >
                        <i class="material-icons">{{ link.class }}</i>
                        <span v-show="modelValue">{{ link.title }}</span>
                </router-link>
            </li>
            <li>
                <a
                    class="waves-effect waves-orange pointer"
                    href="https://12knots.com"
                    target="_blank"
                    @click="handleClick"
                >
                    <i class="material-icons">keyboard_return</i>
                    Website
                </a>
            </li>
        </ul>
    </aside>
</template>

<script>
// import {initialOrder} from '@/types/order.js'

export default {
    props: ["modelValue"],
    emits: ['update:modelValue'],
    data: () => ({
        links: [],
        tooltips: null
    }),
    computed: {
        isClient() {
            return this.$role('client');
        },
        isGuest() {
            return this.$role('client');
        }
    },
    mounted() {
        this.links = this.getLinks();
        // setTimeout( () => {
        //     this.$refs.sb_tooltip.forEach( it => {
        //         if(it.$attrs['data-tooltip-enabled'] === 'true') {
        //              M.Tooltip.init(it.$el, {position: 'right'});
        //         }
        //     })
        // },100)
    },
    methods: {
        newBooking() {
            // this.$store.commit('SET_BOOKING',{...initialOrder})
            window.location.href = '/entryform';
        },

        handleClick() {
          if (window.innerWidth < 992) {
            const isMenuOpenedOnMobile = false;
            this.$emit('update:modelValue', isMenuOpenedOnMobile);
            return
          }

        //   this.$emit('update:modelValue')
        },

        getLinks() {
            if (this.$can("manage-all") || this.$role("super-admin")) {
                return [
                    {title: "Dashboard", url: "/", class: "home", exact: true, tooltip: false},
                    {title: "Bookings list", url: "/bookings", class: "trending_up", tooltip: false},
                    {title: "Booking calendar", url: "/booking-calendar", class: "date_range", tooltip: false},
                    // {title: "History", url: "/history", class: "history"},
                    {title: "Companies", url: "/companies", class: "work", tooltip: false},
                    {title: "Payments", url: "/payments", class: "attach_money", tooltip: false},
                    {title: "Broker Payments", url: "/broker-payments", class: "payment", tooltip: false},
                    {title: "Clients", url: "/clients", class: "perm_identity", tooltip: false},
                    // {title: "Statistic", url: "/statistic", class: "multiline_chart"},
                    // {title: "Profile", url: "/profile", class: "perm_identity"},
                    {title: "Booking terms", url: "/terms", class: "grading", tooltip: false},
                    {title: "Company details", url: "/company-details", class: "settings", tooltip: false},
                    {title: "Users", url: "/users", class: "people", tooltip: false},
                    {title: "Reports", url: "/report", class: "timeline", tooltip: false},
                    {title: "Tasks", url: "/tasks", class: "alarm_on", tooltip: false},
                ];
            }
            else if (this.$role("manager") || this.$role("moderator")) {
                return [
                    {title: "Dashboard", url: "/", class: "home", exact: true, tooltip: false},
                    {title: "Bookings list", url: "/bookings", class: "trending_up", tooltip: false},
                    {title: "Booking calendar", url: "/booking-calendar", class: "date_range", tooltip: false},
                    {title: "Companies", url: "/companies", class: "work", tooltip: false},
                    {title: "Payments", url: "/payments", class: "attach_money", tooltip: false},
                    {title: "Broker Payments", url: "/broker-payments", class: "payment", tooltip: false},
                    {title: "Clients", url: "/clients", class: "perm_identity", tooltip: false},
                    {title: "Users", url: "/users", class: "people", tooltip: false},
                    {title: "Reports", url: "/report", class: "timeline", tooltip: false},
                ];
            }
            else if (this.$role("client")) {
                return [
                    {title: "Home", url: "/", class: "home", exact: true, tooltip: true},
                    {title: "Profile", url: "/account/profile", class: "perm_identity", tooltip: true},
                    {title: "Sailing resume", url: "/account/sailing-resume", class: "grading", tooltip: true},
                    {title: "Bookings", url: "/account/bookings", class: "date_range", tooltip: true},
                ];
            }
            else if(this.$role("guest")) {
                return [{title: "Profile", url: "/user-profile", exact: true}];
            }

            return [];
        },
    },
};
</script>

<style lang="scss" scoped>
.sidenav {
    box-shadow: none;
    overflow-y: initial;
    overflow-x: hidden;

    &__link {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;

        i {
            flex: 0 0 20px;
        }

        span {
            flex-grow: 1;
        }
    }
}

.add {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302),
    0 4px 8px 3px rgba(60, 64, 67, 0.149);
    background-color: #fafafb;
    margin: 25px 10px 15px 10px;
    border-radius: 24px;
    height: 48px;
    min-width: 56px;
    overflow: hidden;
    padding: 0 20px 0;
    text-transform: none;
    display: flex;

    & i.material-icons {
        color: #00a651 !important;
    }
}
</style>
