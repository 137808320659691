import moment from "moment";
import { active_currencies } from "../types/common.js";
import _ from "lodash";
import { parseError } from "@/utils/errorHandler";
export default {
    install(app, options) {
        app.mixin({
            methods: {
                isNotEmpty(someObject) {
                    if (someObject && typeof someObject === "object") {
                        return Object.keys(someObject).length;
                    } else return false;
                },
                moment: function () {
                    return moment();
                },
                rnd(value, precision = 2) {
                    return parseFloat(parseFloat(value).toFixed(precision));
                },
                rndInt(value) {
                    return parseInt(parseFloat(value).toFixed(0));
                },
                reinitializeSelect(element, value) {
                    element.value = value;
                    const { options } = M.FormSelect.getInstance(element); //pass through initialization options;
                    const instance = M.FormSelect.getInstance(element);
                    instance.destroy();
                    return M.FormSelect.init(element, options);
                },
                parseTime(timeStr, dt) {
                    if (!dt) {
                        dt = new Date();
                    }

                    let time = timeStr.match(/(\d+)(?::(\d\d))?\s*(p?)/i);
                    if (!time) {
                        return NaN;
                    }
                    let hours = parseInt(time[1], 10);
                    // if (hours === 12 && !time[3]) {
                    //     hours = 0;
                    // }
                    // else {
                    //     hours += (hours < 12 && time[3]) ? 12 : 0;
                    // }

                    dt.setHours(hours);
                    dt.setMinutes(parseInt(time[2], 10) || 0);
                    dt.setSeconds(0, 0);
                    return dt;
                },
                rndString(length = 6) {
                    return Math.random().toString(20).substr(2, length);
                },
                pick(obj, keys) {
                    return Object.assign(
                        {},
                        ...keys.map((key) => ({ [key]: obj[key] })),
                    );
                },
                getActiveCurrencies() {
                    return [...active_currencies];
                },
                getErrorMessages(error) {
                    return parseError(error);
                },
                errorHandler(error) {
                    const messages = parseError(error);
                    if (messages.length) {
                        messages.forEach((err, index) => {
                            if (err.includes("Unauthenticated")) {
                                console.log(messages);
                                this.$error(err);
                                this.$router.push("/signin");
                            }
                            if (err.includes("email has already been taken")) {
                                messages[index] =
                                    "This e-mail is already in use. You may login or use different e-mail to create new account.";
                            }
                        });
                        console.log(messages);
                        this.$error(messages[0]);
                        this.$store.commit("SET_ERROR", null);
                    }
                },
            },
        });
    },
};
