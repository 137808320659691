import axios from 'axios'
import { method } from 'lodash';

const SALES_REPORT_URL = process.env.VUE_APP_API_PATH + 'v1/sales-report';

export default {
    state: {
        report_orders: [],
        company_sales: null
    },
    getters: {
        report_orders: state => state.report_orders,
        companies_sales: state => state.company_sales,
    },
    mutations: {
        SET_REPORT_ORDERS (state, items) {
            state.report_orders = items;
        },
        SET_COMPANIES_SALES (state, data) {
            state.companies_sales = data;
        },
    },
    actions: {
        fetchReportOrders({commit,dispatch},queryData) {
            return new Promise((resolve, reject) => {
                axios.get(SALES_REPORT_URL + '/orders',{params:queryData}) .then(res => {
                    commit('SET_REPORT_ORDERS',res.data)
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                    reject(err)
                });
            })
        },
        fetchReportCompanies({commit,dispatch},data) {
            return new Promise((resolve, reject) => {
                axios.post(SALES_REPORT_URL + '/company-sales',data) .then(res => {
                    commit('SET_COMPANIES_SALES',res.data)
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                    reject(err)
                });
            })
        },

    },
}
