import store from "../store"

export default {
    install(app, options) {
        app.config.globalProperties.$can = function (ability) {
            const user = store.state.auth.user;
            if (!user) {
                return false;
            }
            if (user.hasOwnProperty('permissions')) {
                const permissions = user.permissions;
                if (permissions.includes(ability)) {
                    return true;
                }
            }
            return false;
        }
        app.config.globalProperties.$role = function (ability) {
            const user = store.state.auth.user;
            if (!user) {
                return false;
            }
            if (user.hasOwnProperty('roles')) {
                const roles = user.roles;
                if (roles.includes(ability)) {
                    return true;
                }
            }
            return false;
        }


    }

}
