class IdleTimer {
    constructor({timeout, onTimeOut, onExpired}) {
        this.timeout = +timeout;
        this.trackingInterval = 5000;
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.onTimeout = onTimeOut;
        this.onExpired = onExpired;
        this.timeOutTracker = null;
        this.tracker();
        this.interval = this.startInterval();
    }

    updateExpiredTime() {
        if(this.timeOutTracker) {
            clearTimeout(this.timeOutTracker);
        }
        this.timeOuttracker = setTimeout (() => {
            localStorage.setItem('_expiredTime', Date.now() + this.timeout * 1000);
        }, 300)
    }

    tracker() {
        window.addEventListener('mousemove', this.eventHandler);
        window.addEventListener('scroll', this.eventHandler);
        window.addEventListener('keydown', this.eventHandler);
    }

    startInterval() {
        this.updateExpiredTime();
        return setInterval( () => {
            const expiredTime = parseInt(localStorage.getItem('_expiredTime') || 0,10);
            if(expiredTime < Date.now()) {
                if(this.onTimeout) {
                    this.onTimeout();
                    this.cleanUp();
                }
            }
        }, this.trackingInterval)
    }

    cleanUp() {
        localStorage.removeItem('_expiredTime');
        clearInterval(this.interval);
        window.removeEventListener('mousemove',this.eventHandler);
        window.removeEventListener('scroll',this.eventHandler);
        window.removeEventListener('keydown',this.eventHandler);
    }
}

export default IdleTimer;
