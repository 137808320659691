/* eslint-disable */
import Vue, { createApp } from "vue";
import useVuelidate from '@vuelidate/core';
import Paginate from "vuejs-paginate-next";
// import VueMeta from 'vue-meta'
import axios from 'axios'

import App from "./App.vue";
import router from "./router";
import store from "./store";


// import dateFilter from "./filters/date.filter";
// import currencyFilter from "./filters/currency.filter";
// import localizeFilter from "./filters/localize.filter";
import tooltipDirective from "./directives/tooltip.directive";

import "materialize-css/dist/js/materialize.min";
import canPluign from "./utils/can.plugin";
// import VModal from 'vue-js-modal';
import { createVfm } from 'vue-final-modal'

import filtersPluign from "./utils/filters.plugin";
import currencyPluign from "./utils/currency.plugin";
import messagePlugin from "./utils/message.plugin";
import titlePlugin from "./utils/title.plugin";
import Loader from "@/components/app/Loader";
import globalMixin from "./utils/globalmixin.plugin"
// import { VueMaskDirective } from 'v-mask'
import { createHead } from "@unhead/vue"


axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.interceptors.request.use(
    (config) => {

        let token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${ token }`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

const vfm = createVfm();

const head = createHead()
const app = createApp(App)
    .use(router)
    .use(store)
    .use(filtersPluign)
    .use(currencyPluign)
    .use(canPluign)
    .use(messagePlugin)
    .use(titlePlugin)
    .use(head)
    .use(globalMixin)
    .use(vfm)
    // .use(VModal)
    // .use(require('vue-moment'))
    .use(useVuelidate)
    .directive("tooltip", tooltipDirective)
    .component("Loader", Loader)
    .component("Paginate", Paginate);


    app.mount('#app');


