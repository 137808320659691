import Vue from "vue";
import { createRouter, createWebHistory } from "vue-router";
import store from "./store";

const router = createRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "home",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager", "client"],
                title: "Account 12 Knots",
                description: "Account 12 Knots",
            },
            component: () => import("./views/Home.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/auth/:provider/callback",
            name: "loginsocial",
            meta: { layout: "empty", auth: false },
            component: () => import("./views/LoginSocial.vue"),
        },
        {
            path: "/signin",
            name: "login",
            meta: {
                layout: "empty",
                auth: false,
                title: "Sign in to your account | 12 Knots",
                description:
                    "Professional charter experience with 12 Knots. From bareboat rentals to crewed yacht charters – we are the leading booking platform",
            },
            component: () => import("./views/Login.vue"),
        },
        {
            path: "/register-user",
            name: "register-user",
            meta: {
                layout: "empty",
                auth: false,
                title: "Create account | 12 Knots",
                description: "Create account | 12 Knots",
            },
            component: () => import("./views/ClientRegister.vue"),
        },
        {
            path: "/forgot",
            name: "forgot",
            meta: { layout: "empty", auth: false },
            component: () => import("./views/Forgot.vue"),
        },
        {
            path: "/reset-password",
            name: "resetpassword",
            meta: { layout: "empty", auth: false },
            component: () => import("./views/ResetPassword.vue"),
        },
        {
            path: "/register",
            name: "register",
            meta: {
                layout: "empty",
                auth: true,
                roles: ["super-admin"],
                title: "Create account | 12 Knots",
                description: "Create account | 12 Knots",
            },
            component: () => import("./views/Register.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/verify-email",
            name: "verifyEmail",
            component: () => import("./views/VerifyEmail.vue"),
        },
        {
            path: "/companies",
            name: "companies",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/Companies.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/company/:id",
            name: "companycard",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/CompanyCard.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/new-company",
            name: "newcompany",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/CompanyCard.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/payments",
            name: "payments",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/PaymentList.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/broker-payments",
            name: "brokerpayments",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/BrokerPaymentList.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/clients",
            name: "clients",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/Clients.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/client/:id?",
            name: "clientcard",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/ClientCard.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/profile",
            name: "profile",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/Profile.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/agreement/:orderId",
            name: "agreement",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/Agreement.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/offer/:orderId",
            name: "offer",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/Offer.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/invoice/:orderId/:paymNum",
            name: "invoice",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/Invoice.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/terms",
            name: "terms",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin"],
            },
            component: () => import("./views/Terms.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },

        {
            path: "/bookings",
            name: "bookings",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/Bookings.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/booking-calendar",
            name: "bookingcalendar",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/BookingCalendar.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/company-details",
            name: "companydetails",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator"],
            },
            component: () => import("./views/CompanyDetails.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/entryform/:id?",
            name: "entryform",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/EntryForm.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/users",
            name: "users",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/Users.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/user/:id?",
            name: "usercard",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "moderator", "manager"],
            },
            component: () => import("./views/UserCard.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/report",
            name: "report",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "manager", "moderator"],
            },
            component: () => import("./views/Report.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/report/sales",
            name: "salesReport",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "manager", "moderator"],
            },
            component: () => import("./views/report/ManagerSalesReport.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/report/companies",
            name: "salesCompaniesReport",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "manager", "moderator"],
            },
            component: () => import("./views/report/CompanySalesReport.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/report/client-country",
            name: "salesClientCountryReport",
            meta: {
                layout: "main",
                auth: true,
                roles: ["super-admin", "manager", "moderator"],
            },
            component: () =>
                import("./views/report/ClientCountrySalesReport.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        //// Client account routes
        {
            path: "/account/profile",
            name: "clientprofile",
            meta: { layout: "client", auth: true, roles: ["client"] },
            component: () => import("./views/account/ClientProfile.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/account/sailing-resume",
            name: "clientsailingresume",
            meta: { layout: "client", auth: true, roles: ["client"] },
            component: () => import("./views/account/ClientSailingResume.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/account/bookings",
            name: "clientbookings",
            meta: { layout: "client", auth: true, roles: ["client"] },
            component: () => import("./views/account/ClientBookings.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/account/bookings/:id",
            name: "clientbookingsdetails",
            meta: { layout: "client", auth: true, roles: ["client"] },
            component: () => import("./views/account/ClientBookingsDetail.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/printresume/:client_id/:order_id?",
            name: "printresume",
            meta: {
                layout: "print",
                auth: true,
                roles: ["super-admin", "moderator", "manager", "client"],
            },
            component: () =>
                import("./components/print-versions/PSailingResume.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/printlist/:order_id",
            name: "printlist",
            meta: {
                layout: "print",
                auth: true,
                roles: ["super-admin", "moderator", "manager", "client"],
            },
            component: () =>
                import("./components/print-versions/PCrewList.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },
        {
            path: "/tasks",
            name: "tasks",
            meta: { layout: "main", auth: true, roles: ["super-admin"] },
            component: () => import("./views/Tasks.vue"),
            beforeEnter: (to, from, next) => {
                guard(to, from, next);
            },
        },

        //// 404
        {
            path: "/:catchAll(.*)",
            name: "404",
            component: () => import("./views/404.vue"),
        },
    ],
});

const DEFAULT_TITLE = "Account | 12 Knots";
const DEFAULT_META_DESCRIPTION = "Account | 12 Knots";
router.beforeEach((to, from, next) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    // Vue.nextTick(() => {
    //     document.title = to.meta.title || DEFAULT_TITLE;
    //     document.querySelector('meta[name="description"]').setAttribute("content", (to.meta.description || DEFAULT_META_DESCRIPTION));
    // });
    next();
});

const guard = function (to, from, next) {
    // check for valid auth token
    if (to.matched.some((record) => record.meta.auth)) {
        let user = store.state.auth.user;
        if (user && Object.keys(user).length && user.id) {
            const rolesList = [];
            if (user.roles && _.isArray(user.roles)) {
                user.roles.forEach((it) => {
                    if (_.isString(it)) {
                        rolesList.push(it);
                    } else if (_.isObject(it) && it?.name) {
                        rolesList.push(it.name);
                    }
                });
            }
            if (to.meta?.hasOwnProperty("roles")) {
                if (!user || !user.hasOwnProperty("roles"))
                    next("/signin?message=not_allowed");
                let intersection = to.meta.roles.filter((x) =>
                    rolesList.includes(x),
                );
                if (intersection && intersection.length) {
                    next();
                    return;
                } else {
                    next("/signin?message=not_allowed");
                    return;
                }
            }
            next();
        } else {
            store
                .dispatch("me")
                .then((res) => {
                    guard(to, from, next);
                })
                .catch((err) => {
                    console.log(err);
                    next("/signin?message=" + err.error);
                });
        }
    }
};

export default router;
