<template>
  <aside class="client-sidebar"
  :class="sidebarClasses"
  >
    <transition-group name="slide-fade">
      <div class="no-transition" key="0" v-if="menuLevelVisible === 0">
        <ul class="client-sidebar__list list-first">
          <li v-for="link in configMenu.sidebarMenu" :class="{ 'has-children': link.children.list }"
              :key="link"
              class="client-sidebar__item list-first__item" ref="links">
            <button class="btn-reset" v-if="link.children.list" @click="showFirstSubmenu(link)">{{ link.title }}</button>
            <a v-else :href='"https://12knots.com" + link.link'>{{ link.title }}</a>
          </li>
        </ul>
      </div>

      <div key="1" class="client-submenu" ref="submenu" v-else-if="(menuLevelVisible === 1)">
        <button @click="showPrevious(0)" class="client-sidebar__btn btn-reset">
          <icon-arrow-back/>Back
        </button>
        <ul class="client-submenu__list client-submenu__list_second">
          <li v-for="link in firstSubmenu.children.list" :key="link" class="client-sidebar__item" :class="{ 'has-children': link.linkto }">
            <button class="btn-reset" v-if="link.children.list" @click="showSecondSubmenu(link)">
              {{ link.title }}
            </button>
            <a v-else :href='"https://12knots.com" + link.link'>{{ link.title }}</a>
          </li>
        </ul>
      </div>

      <div key="2" class="client-submenu" ref="submenu" v-else>
        <button @click="showPrevious(1)" class="client-sidebar__btn btn-reset">
          <icon-arrow-back/>Back
        </button>
        <ul class="client-submenu__list client-submenu__list_last">
          <li v-for="link in secondSubmenu.children.list" :key="link" class="client-sidebar__item" :class="{ 'has-children': link.linkto }">
            <button class="btn-reset" v-if="link.linkto">
              {{ link.title }}
            </button>
            <a v-else :href='"https://12knots.com" + link.link'>{{ link.title }}</a>
          </li>
        </ul>
      </div>
    </transition-group>

    <div v-show="menuLevelVisible === 0" class="client-sidebar__footer">
      <ul class="client-sidebar__contacts">
        <li class="client-sidebar__contacts-item item-email nav-link">
          <a href="tel:+18889173773">
            <icon-phone /><span>+1 888 917 37 73</span>
          </a>
        </li>
        <li class="client-sidebar__contacts-item item-phone nav-link">
          <a href="mailto:12@12knots.com">
            <icon-mail /><span>Email: 12@12knots.com</span>
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { menu } from '../../types/sidemenu';
import { mapGetters } from 'vuex';
import IconArrowBack from '../atoms/IconArrowBack.vue';
import IconPhone from '../atoms/IconPhone.vue';
import IconMail from '../atoms/IconMail.vue';

export default {
  components: {IconArrowBack, IconPhone, IconMail},
  data: () => ({
    menuLevelVisible: 0,
    firstSubmenu: {},
    secondSubmenu: {},
    menuAnimationForward: true
  }),
  props: {
    isMenuOpen: Boolean,
  },
  methods: {
    showPrevious(level) {
      this.menuAnimationForward = false;
      setTimeout(() => {
        this.menuAnimationForward = true;
      }, 300);
      this.menuLevelVisible = level;
    },
    showFirstSubmenu(link) {
      this.menuLevelVisible = 1;
      const [submenu] = this.configMenu.firstSubmenu
        .filter(i => i.children.submenuName === link.linkto);
      this.firstSubmenu = submenu;
    },
    showSecondSubmenu(link) {
      this.menuLevelVisible = 2;
      const [submenu] = this.configMenu.secondSubmenu
        .filter(i => i.children.submenuName === link.linkto);
      this.secondSubmenu = submenu;
    },
    injectSecondSubmenu(menuToChange ,menuItemIndex, submenuItemIndex, submenuName, submenuObject) {
      menuToChange[menuItemIndex].children.list[submenuItemIndex].linkto = submenuName
      menuToChange[menuItemIndex].children.list[submenuItemIndex].children.list = submenuObject;
      menuToChange[menuItemIndex].children.list[submenuItemIndex].children.submenuName = submenuName;
    }
  },
  computed: {
    ...mapGetters([
      'side_menu_data'
    ]),

    sidebarClasses() {
      return {
        'visible': this.isMenuOpen,
        'reverse': !this.menuAnimationForward,
        'main-menu-showh': this.menuLevelVisible === 0,
        'first-submenu-showh': this.menuLevelVisible === 1,
        'second-submenu-showh': this.menuLevelVisible === 2,
      }
    },

    menuObj() {
      return [...menu]
    },

    configMenu() {
      const sidebarMenu = this.menuObj;
      const {
        charter_countries,
        charter_destinations,
        cruise_countries,
        cruise_destinations
      } = this.side_menu_data;

      this.injectSecondSubmenu(sidebarMenu, 1, 2, 'cruise_countries', cruise_countries);
      this.injectSecondSubmenu(sidebarMenu, 1, 3, 'cruise_destinations', cruise_destinations);
      this.injectSecondSubmenu(sidebarMenu, 2, 3, 'charter_countries', charter_countries);
      this.injectSecondSubmenu(sidebarMenu, 2, 4, 'charter_destinations', charter_destinations);

      const firstSubmenu = sidebarMenu.filter(menu => menu.children.list);
      const secondSubmenu = firstSubmenu.map(obj => obj.children.list).flat().filter(x => x.children.list);

      return { sidebarMenu, firstSubmenu, secondSubmenu }
    }
  },
};
</script>

<style lang="scss">

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

  .slide-fade-enter {
    transform: translateX(100%);
    opacity: 0;
  }

  .slide-fade-leave-to {
    opacity: 0;
  }

  .reverse {
    .slide-fade-enter {
      transform: translateX(0);
      opacity: 0;
    }

    .slide-fade-leave-to {
      transform: translateX(100%);
      opacity: 0;
    }
  }
</style>
