import axios from 'axios'

export default {
    state: {
        terms: null
    },
    getters: {
        terms: state => state.terms
    },
    mutations: {
        SET_TERMS(state, terms) {
            state.terms = terms;
        },
    },
    actions: {
        fetchTerms({commit,dispatch},queryData) {
            return new Promise((resolve, reject) => {

                axios.get(process.env.VUE_APP_API_PATH + 'v1/booking-terms',{params:queryData}) .then(res => {
                    commit('SET_TERMS',res.data)
                    resolve();
                }).catch(err => {
                    commit('SET_ERROR',err);
                    reject(err)
                });
            })
        },
        storeTerms({commit},data) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_API_PATH + 'v1/booking-terms',data) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                    reject(err)
                });
            })
        },
        updateTerms({commit},data) {
            return new Promise((resolve, reject) => {
                axios.put(process.env.VUE_APP_API_PATH + 'v1/booking-terms/'+data['id'],data) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                    reject(err)
                });
            })
        },
        deleteTerms({commit},id) {
            return new Promise((resolve, reject) => {
                axios.delete(process.env.VUE_APP_API_PATH + 'v1/booking-terms/'+id) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                    reject(err)
                });
            })
        },
    }
}
