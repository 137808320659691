import axios from "axios";
const common_url = process.env.VUE_APP_API_PATH + "v1/common";
export default {
    state: {
        currency: null,
        client_currency: null,
        broker_currency: null,
        companies: null,
        yachts: null,
        managers: null,
        amount_to_pay: null,
        company_details: null,
        side_menu_data: {},
    },
    getters: {
        currency: (state) => state.currency,
        companies: (state) => state.companies,
        yachts: (state) => state.yachts,
        managers: (state) => state.managers,
        client_currency: (state) => state.client_currency,
        broker_currency: (state) => state.broker_currency,
        amount_to_pay: (state) => state.amount_to_pay,
        company_details: (state) => state.company_details,
        side_menu_data: (state) => state.side_menu_data,
    },
    mutations: {
        SET_CURRENCY(state, currency) {
            state.currency = currency;
        },
        SET_COMPANIES(state, items) {
            state.companies = items;
        },
        SET_YACHTS(state, items) {
            state.yachts = items;
        },
        SET_MANAGERS(state, items) {
            state.managers = items;
        },
        SET_CLIENT_CURRENCY(state, currency) {
            state.client_currency = currency;
        },
        SET_BROKER_CURRENCY(state, currency) {
            state.broker_currency = currency;
        },
        SET_AMOUNT_TO_PAY(state, amount) {
            state.amount_to_pay = amount;
        },
        SET_DETAILS(state, details) {
            state.company_details = details;
        },
        SET_SIDE_MENU_DATA(state, data) {
            state.side_menu_data = data;
        },
    },
    actions: {
        fetchCompanies({ commit, dispatch }, search = null) {
            return new Promise((resolve, reject) => {
                axios
                    .get(process.env.VUE_APP_API_PATH + "v1/companies", {
                        params: search,
                    })
                    .then((res) => {
                        if (typeof res.data === "object") {
                            commit("SET_COMPANIES", res.data);
                        }
                        resolve(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        commit("SET_ERROR", err?.response?.data);
                        reject(err);
                    });
            });
        },
        fetchYachts({ commit, dispatch }, search = "") {
            return new Promise((resolve, reject) => {
                axios
                    .get(process.env.VUE_APP_API_PATH + "v1/boats?" + search)
                    .then((res) => {
                        if (typeof res.data === "object") {
                            commit("SET_YACHTS", res.data);
                        }
                        resolve();
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },
        fetchManagers({ commit, dispatch }, search = "") {
            return new Promise((resolve, reject) => {
                axios
                    .get(process.env.VUE_APP_API_PATH + "v1/managers")
                    .then((res) => {
                        if (typeof res.data === "object") {
                            commit("SET_MANAGERS", res.data);
                        }
                        resolve();
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },
        searchManagers({ commit, dispatch }, search = "") {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        process.env.VUE_APP_API_PATH +
                            "v1/search/?class=manager&field=name&search=" +
                            search,
                    )
                    .then((res) => {
                        if (typeof res.data === "object") {
                            commit("SET_MANAGERS", res.data);
                        }
                        resolve();
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },
        fetchDetails({ commit, dispatch }, queryData) {
            return new Promise((resolve, reject) => {
                axios
                    .get(process.env.VUE_APP_API_PATH + "v1/company-details", {
                        params: queryData,
                    })
                    .then((res) => {
                        commit("SET_DETAILS", res.data);
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },
        storeDetails({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        process.env.VUE_APP_API_PATH + "v1/company-details",
                        data,
                    )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },
        updateDetails({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put(
                        process.env.VUE_APP_API_PATH +
                            "v1/company-details/" +
                            data["id"],
                        data,
                    )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },
        deleteDetails({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(
                        process.env.VUE_APP_API_PATH +
                            "v1/company-details/" +
                            id,
                    )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },
        deleteMedia({ commit }, mediaId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(common_url + `/delete-media/${mediaId}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },

        getMediaFile({ commit }, mediaId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(common_url + `/get-media-file/${mediaId}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },
        downloadMediaFile({ commit }, mediaId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(common_url + `/download/${mediaId}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },

        getMediaFileAsString({ commit }, mediaId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(common_url + `/get-media-file-as-string/${mediaId}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err.response.data);
                        reject(err);
                    });
            });
        },

        async fetchSideMenuData({ commit }) {
            try {
                const api =
                    "https://12knots.com/system/side-menu/?apiKey=e6d1555b-d334-4875-8f86-231b8066b499";
                let response = await fetch(api);
                const side_menu_data = await response.json();
                // const side_menu_data = await axios.get('https://12knots.com/system/side-menu/?apiKey=e6d1555b-d334-4875-8f86-231b8066b499');
                if (side_menu_data) {
                    commit("SET_SIDE_MENU_DATA", side_menu_data);
                }
                return side_menu_data;
            } catch (err) {
                commit("SET_ERROR", err.response.data);
                return false;
            }
        },
    },
};
