<template>
    <div v-if="!isClient" class="app-main-layout">
        <Navbar @toggleMenu="isOpen = !isOpen"/>
        <Sidebar v-model:modelValue="isOpen" :key="locale" />
        <Loader v-if="loading" />
        <main v-else class="app-content" :class="{'swipe-right-on-menu-open' : isOpen}">
            <router-view :key="$route.fullPath" />
        </main>
    </div>
    <div v-else-if="isClient" class="client-layout" >
        <Navbar @click="isOpen = !isOpen"/>
        <div
            class="client-container"
        >
            <div class="client-layout__content" :class="{'swipe-right-on-menu-open' : isOpen}">
                <Sidebar v-model:modelValue="isOpen" :key="locale" />
                <div class="w-100">
                    <Loader v-if="loading" />
                    <main v-else>
                        <router-view :key="$route.fullPath" />
                    </main>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Sidebar from "@/components/app/Sidebar";
// import ClientNavbar from "@/components/app/ClientNavbar";

import moment from "moment";
import _ from "lodash";

export default {

    data: () => ({
        info: {},
        isOpen: false,
        loading: true,
    }),
    methods: {
        closeMobileMenu() {
            if (window.screen.width < 768) {
                this.isOpen = !this.isOpen;
            }
        },
        async updateCurrencies() {
            const cur = await this.$store.dispatch("fetchCur");
            console.log("Currencies rates updated!", cur);
        },
        toggleMenuState() {
          this.isMenuOpen = !this.isMenuOpen
        },
    },
    async mounted() {
        if (!this.isNotEmpty(this.info)) {
            try {
                await this.$store.dispatch("fetchInfo");
                this.info = this.$store.getters.info;
            } catch (err) {
                this.info = {};
                this.$error("Error getting info");
            }
        }
        let curData = localStorage.getItem("currencies");
        if (typeof curData === "string") {
            curData = JSON.parse(curData);
            if (
                moment(curData.date).format("YYYY-MM-DD") !==
                moment().format("YYYY-MM-DD")
            ) {
                this.updateCurrencies();
            }
        } else {
            this.updateCurrencies();
        }
        this.loading = false;
        if(this.$role('client') &&
            !this.$role('manager')
            && !this.$role('moderator')
            && !this.$role('super-admin')) {
            this.$router.push({ name: 'home'});
        }
    },
    components: {
        // ClientNavbar,
        Navbar,
        Sidebar,
    },
    computed: {
        isClient() {
            return this.$role("client");
        },
        error() {
            return this.$store.getters.error;
        },
        locale() {
            return this.isNotEmpty(this.info) ? this.info.locale : "en-US";
        }
    },
    watch: {
        error(error) {
            this.errorHandler(error)
        }
    },
};
</script>
