import _ from "lodash";

export function parseError(error) {
    let errorMessages = [];
    // if(error?.response && (error.response.status === 404 || error.response.status === 422)) {
    //
    // }
    if(error?.response?.data && error?.response?.data.error) {
        error = error.response.data.error;
    }
    if(_.isString(error)) {
        addMessage(errorMessages,error);
    } else if (_.isArray(error)) {
        error.forEach(e => {
            if(_.isString(e)) {
                addMessage(errorMessages,e);
            }
        });
    } else if (error?.response) {
        if(error.response?.data) {
            if(_.isArray(error.response?.data)) {
                error.response.data.forEach(e => {
                    addMessage(errorMessages,e);
                });
            } else if(_.isString(error.response.data)) {
                addMessage(errorMessages,error.response.data);
            } else if (typeof error.response.data === "object") {
                for (const [key, value] of Object.entries(error.response.data))  {
                    if(_.isString(value)) {
                        addMessage(errorMessages,value);
                    } else if(_.isArray(value)) {
                        value.forEach(e => {
                            if(_.isString(e)) {
                                addMessage(errorMessages,e);
                            }
                        });
                    }
                }
            }
        }
    } else if (error && typeof error === "object") {
        for (const [key, value] of Object.entries(error))  {
            if(_.isString(value)) {
                addMessage(errorMessages,value);
            } else if(_.isArray(value)) {
                value.forEach(e => {
                    if(_.isString(e)) {
                        addMessage(errorMessages,e);
                    }
                });
            }
        }
    } else {
        console.log(error);
    }

    return errorMessages;
}

function addMessage(messages, message) {
    if(!_.isArray(messages)) {
        messages = [];
    }
    if(!messages.includes(message)) {
        messages.push(message);
    }
}
