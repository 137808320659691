import store from "../store";
import _ from "lodash";

var currency_symbols = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    RUB: "₽", // RUB
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
    CAD: "CA$", // Vietnamese Dong
    NOK: "kr", // Vietnamese Dong
};

export default {
    install(app, options) {
        (app.config.globalProperties.$convert = function (
            value,
            from_currency,
            to_currency,
        ) {
            // console.log(value,from_currency,to_currency);
            value = parseFloat(value);
            if (from_currency === to_currency) {
                return value;
            }
            let currencies = null;
            if (!_.isEmpty(store.getters.currencies)) {
                currencies = store.getters.currencies;
            } else if (localStorage.getItem("currencies") !== null) {
                currencies = JSON.parse(localStorage.getItem("currencies"));
            }
            if (_.isEmpty(currencies)) {
                const err = "Currencies error!!!";
                store.commit("SET_ERROR", err);
                console.log(err);
                this.$message(err);
                return value;
            }
            const from_cur_rate = currencies.rates[from_currency]
                ? currencies.rates[from_currency]
                : 0;
            const to_cur_rate = currencies.rates[from_currency]
                ? currencies.rates[to_currency]
                : 0;
            if (from_cur_rate && to_cur_rate) {
                return parseFloat(
                    ((value / from_cur_rate) * to_cur_rate).toFixed(2),
                );
            }
            // if(to_currency !== currencies.base && from_currency !== currencies.base) {
            //     const rate = currencies.rates[from_currency];
            //     const base = value / rate;
            //     return parseFloat((base * value).toFixed(2));
            // }
            // else if(to_currency === currencies.base && currencies.rates[from_currency]) {
            //     const rate = currencies.rates[from_currency];
            //     return parseFloat((value * rate).toFixed(2));
            // }
            // else if(from_currency === currencies.base && currencies.rates[to_currency]) {
            //     const rate = currencies.rates[to_currency];
            //     return parseFloat((value * rate).toFixed(2));
            // }

            return value;
        }),
            (app.config.globalProperties.$cursym = function (currency_name) {
                if (currency_symbols[currency_name] !== undefined) {
                    return currency_symbols[currency_name];
                } else {
                    return currency_name;
                }
            });
    },
};
