<template>
    <div class="print-layout">
        <Navbar />
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
export default {
  components: {Navbar},
    computed: {
        error() {
            return this.$store.getters.error;
        },
    },
    watch: {
        error(error) {
            this.errorHandler(error)
        }
    },
    methods: {

    },
};
</script>
<style lang="scss" scoped>

.print-layout {
  display: flex;
  justify-content: center;
   width: 100%;
   margin: 0 auto;
}
</style>
