import axios from "axios";

const TODO_LIST_ITEMS_URL = process.env.VUE_APP_API_PATH + "v1/todo-list-items";
const TODO_LIST_URL = process.env.VUE_APP_API_PATH + "v1/todo-lists";
const TASKS_LIST_URL = process.env.VUE_APP_API_PATH + "v1/todo-tasks";

export default {
    state: {
        todo_list: null,
        todo_list_items: null,
        tasks: null,
    },
    getters: {
        todo_list_items: (state) => state.todo_list_items,
        todo_list: (state) => state.todo_list,
        tasks: (state) => state.tasks,
    },
    mutations: {
        SET_TODO_LIST(state, todo_list) {
            state.todo_list = todo_list;
        },
        SET_TODO_LIST_ITEMS(state, items) {
            state.todo_list_items = items;
        },
        SET_TASKS(state, items) {
            state.tasks = items;
        },
    },
    actions: {
        fetchTodoListItems({ commit }, queryData, withCommit = true) {
            return new Promise((resolve, reject) => {
                axios
                    .get(TODO_LIST_ITEMS_URL, { params: queryData })
                    .then((res) => {
                        if (withCommit) {
                            commit("SET_TODO_LIST_ITEMS", res.data);
                        }
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err);
                        reject(err);
                    });
            });
        },
        createTodoList({ commit }, data) {
            if (!data.name && data.order_id) {
                data.name = "Todo list for order " + data.order_id;
            }
            return new Promise((resolve, reject) => {
                axios
                    .post(TODO_LIST_URL, data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err);
                    });
            });
        },
        storeTodoItem({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(TODO_LIST_ITEMS_URL, data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err);
                    });
            });
        },
        updateTodoItem({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put(TODO_LIST_ITEMS_URL + "/" + data["id"], data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err);
                    });
            });
        },
        deleteTodoItem({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(TODO_LIST_ITEMS_URL + "/" + id)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err);
                    });
            });
        },
        fetchTasks({ commit }, queryData) {
            return new Promise((resolve, reject) => {
                axios
                    .get(TASKS_LIST_URL, { params: queryData })
                    .then((res) => {
                        commit("SET_TASKS", res.data);
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err);
                    });
            });
        },
        storeTask({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(TASKS_LIST_URL, data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err);
                    });
            });
        },
        updateTask({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put(TASKS_LIST_URL + "/" + data["id"], data)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err);
                    });
            });
        },
        deleteTask({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(TASKS_LIST_URL + "/" + id)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        commit("SET_ERROR", err);
                    });
            });
        },
    },
};
