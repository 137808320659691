import axios from 'axios'
const url = process.env.VUE_APP_API_PATH + 'v1/companies';
import _ from "lodash";
export default {
    state: {
        company: null,
    },
    getters: {
        company: state => state.company,
    },
    mutations: {
        SET_COMPANY(state, company) {
            state.company = company;
        },
    },
    actions: {
        fetchCompany({commit,dispatch},id) {
            return new Promise((resolve, reject) => {
                axios.get(url + '/' + id).then(res => {
                    if(res.data && typeof res.data === 'object') {
                        commit('SET_COMPANY',res.data)
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            });
        },
        deleteCompany({commit},id) {
            return new Promise((resolve, reject) => {
                if(!id) {
                    reject("no id provided");
                }
                axios.delete(url + '/' + id) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },
        storeCompany({commit},data) {
            return new Promise((resolve, reject) => {
                axios.post(url,data) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },
        updateCompany({commit},data) {
            return new Promise((resolve, reject) => {
                axios.put(url + '/'+data['id'],data) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },


    }
}
