import axios from 'axios'
const messages_url = process.env.VUE_APP_API_PATH + 'v1/ws-messages';
export default {
    //namespaced: true,
    state: {
        user: {},
        token: localStorage.getItem('token') || '',
        authenticated: localStorage.getItem('authenticated') || '',
        auth_url: null
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_AUTH_URL(state, url) {
            state.auth_url = url;
        },

        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value
        },
    },
    actions: {

        async signIn({commit, dispatch}, credentials) {
            try {
                localStorage.removeItem('token');
                credentials.device_name = 'client12knots';
                const res = await axios.post('/api/v1/token', credentials)
                if(res && res.data?.send_verification_link) {
                    return res.data;
                }
                if (res && res?.data?.token) {
                    const token = res.data.token;
                    commit('SET_TOKEN', token);
                    localStorage.setItem('token', token);
                    const response = await axios.get(process.env.VUE_APP_API_PATH + 'v1/user');
                    if (response?.data) {
                        commit('SET_AUTHENTICATED', true)
                        commit('SET_USER', response.data)
                        return response.data;
                    }
                }

                // Old behaviour
                // } else {
                //     await axios.get('/sanctum/csrf-cookie')
                //     await axios.post('/login', credentials)
                //     const res = await dispatch('me')
                //     // console.log(res);
                //     return res
                // }
            } catch (error) {
                commit('SET_ERROR', error)
                commit('SET_AUTHENTICATED', false)
                commit('SET_USER', null)
                console.log(error)
            }


        },

        async signOut({commit}) {
            //await axios.post('/logout')
            await axios.post(process.env.VUE_APP_API_PATH + 'v1/sign-out')
            localStorage.setItem('authenticated', '0');
            localStorage.removeItem('token');
            commit('SET_AUTHENTICATED', false)
            commit('SET_USER', null);
            commit('SET_CLIENT', null);
            // return dispatch('me')
        },

        me({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_PATH + 'v1/user').then((response) => {
                    localStorage.setItem('authenticated', '1');
                    commit('SET_AUTHENTICATED', true)
                    commit('SET_USER', response.data)
                    resolve(response.data)
                }).catch((err) => {
                    localStorage.setItem('authenticated', '0');
                    commit('SET_AUTHENTICATED', false)
                    commit('SET_USER', null)
                    reject(err)
                })
            })
        },
        getUserData({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_PATH + 'v1/user').then((response) => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        login({commit}, user) {
            return new Promise((resolve, reject) => {
                axios({url: process.env.VUE_APP_API_PATH + 'auth/login/', data: user, method: 'POST'})
                    .then(res => {
                        if(res.data?.send_verification_link) {
                            resolve(res.data);
                        }
                        const token = res.data.token
                        const user = res.data.user
                        commit('setUser', user);
                        commit('SET_TOKEN', token);
                        localStorage.setItem('token', token);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                        commit('CLEAR_ERROR');
                        resolve(res)
                    })
                    .catch(err => {
                        commit('SET_ERROR', err);
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },

        getAuthUrl({commit}, provider) {
            return new Promise((resolve, reject) => {
                axios({url: process.env.VUE_APP_API_PATH + `get-auth-url/${provider}`, method: 'GET'})
                    .then(res => {
                        commit('SET_AUTH_URL', res.data);
                        resolve();
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    })
            })
        },
        async authUrlCallback({commit}, payload) {
            try {
                console.log('Social callback');
                localStorage.removeItem('token');
                const res = await axios({
                    url: process.env.VUE_APP_API_PATH + `login/${payload.provider}/callback`,
                    params: {
                        code: payload.code
                    },
                    method: 'GET'
                });
                console.log(res.data);
                if (res.data.token) {
                    const token = res.data.token
                    const user = res.data.user
                    commit('SET_TOKEN', token);
                    localStorage.setItem('token', token);
                    if (user) {
                        const rolesList = [];
                        if (user.roles && _.isArray(user.roles)) {
                            user.roles.forEach(it => {
                                if (_.isString(it)) {
                                    rolesList.push(it);
                                } else if (_.isObject(it) && it?.name) {
                                    rolesList.push(it.name);
                                }
                            })
                        }
                        user.roles = rolesList;
                        commit('SET_AUTHENTICATED', true)
                        commit('SET_USER', user)
                        return user;
                    } else {
                        throw new Error("can not find user");
                    }
                }
            } catch (error) {
                commit('SET_ERROR', error)
                commit('SET_AUTHENTICATED', false)
                commit('SET_USER', null)
                console.log(error)
            }

        },

        authUrlCallbackOld({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    url: process.env.VUE_APP_API_PATH + `login/${payload.provider}/callback`,
                    params: {
                        code: payload.code
                    },
                    method: 'GET'
                })
                    .then(res => {
                        console.log('Social callback');
                        console.log(res.data);
                        if (res.data.token) {
                            const token = res.data.token
                            // const user = res.data.user
                            commit('SET_TOKEN', token);
                            localStorage.setItem('token', token);
                            axios.get(process.env.VUE_APP_API_PATH + 'v1/user').then(response => {
                                console.log(response)
                                if (response?.data) {
                                    commit('SET_AUTHENTICATED', true)
                                    commit('SET_USER', response.data)
                                    return response.data;
                                }
                            }).catch(err => {
                                commit('SET_ERROR', err);
                                localStorage.removeItem('token')
                                reject(err)
                            })
                            //axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                            commit('CLEAR_ERROR');
                            resolve(res)
                        }
                    })
                    .catch(err => {
                        // commit('SET_ERROR',err);
                        // localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },

        register({commit}, user) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/register/', user).then(res => {
                    resolve(res.data)
                }).catch(err => {
                    localStorage.removeItem('token')
                    reject(err)
                });
            })
        },
        register_october({commit}, user) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_API_PATH + 'auth/register/', user).then(res => {
                    resolve(res.data)
                }).catch(err => {
                    commit('SET_ERROR', err);
                    localStorage.removeItem('token')
                    reject(err)
                });
            })
        },

        getUser({commit}) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token') || '',
                    }
                };

                axios.get(process.env.VUE_APP_API_PATH + 'auth/me/', config).then(res => {
                    if (res.data) {
                        commit('setUser', res.data.user);
                        resolve(res.data)
                    } else {
                        console.log(res);
                    }
                }).catch(err => {
                    console.log(err)
                    reject(err);
                });
            });

        },
        async logout({commit}) {
            console.log('logout');
            // await firebase.auth().signOut()
            localStorage.removeItem('token');
            await commit('setUser', {})
            await commit('setToken', '')
        },

        refresh({commit}) {
            return new Promise((resolve, reject) => {
                const data = {
                    token: this.getters.token
                }
                axios({url: process.env.VUE_APP_API_PATH + 'auth/refresh-token/', data, method: 'POST'})
                    .then(res => {
                        const token = res.data.token
                        commit('setToken', token);
                        localStorage.setItem('token', token);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                        commit('CLEAR_ERROR');
                        resolve(res)
                    })
                    .catch(err => {
                        commit('SET_ERROR', err);
                        localStorage.removeItem('token')
                        commit('setUser', {});
                        reject(err)
                    })
            })
        },
        resendEmailVerificationLink({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_PATH + 'v1/email-verification-resend', {
                    params: payload
                }).then(res => {
                    if (res.data) {
                        resolve(res.data)
                    } else {
                        console.log(res);
                        reject("Empty response data");
                    }
                }).catch(err => {
                    console.log(err)
                    reject(err);
                });
            });

        },

        verifyEmail({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_PATH + 'v1/email-verification', {
                    params: payload
                }).then(res => {
                    if (res.data) {
                        resolve(res.data)
                    } else {
                        console.log(res);
                        reject("Empty response data");
                    }
                }).catch(err => {
                    console.log(err)
                    commit('SET_ERROR',err);
                    reject(err);
                });
            });

        },
        deleteMessage({commit}, id) {
            return new Promise((resolve, reject) => {
                if(!id) {
                    reject("id is not provided");
                }
                axios.delete(messages_url + `/${id}`) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        }

    },
    getters: {
        authenticated(state) {
            return state.authenticated
        },
        user: state => state.user,
        messages: state => state.user ? state.user.messages : [],
        token: state => state.token,
        auth_url: state => state.auth_url,
    },

}
