import store from "@/store";
import moment from "moment"

export default {
    install(app, options) {
        app.config.globalProperties.$filters = {
            currency(value, currency = 'EUR',maxdecimals = 2) {
                if(!currency) {
                    currency = 'EUR';
                }
                if(currency === '%') {
                    return value+'%';
                }
                if(isNaN(value) || !value) {
                    value = 0;
                }
                if(maxdecimals === 0) {
                    value = parseInt(value)
                }

                return new Intl.NumberFormat('en-EN', {
                    maximumFractionDigits: maxdecimals,
                    minimumFractionDigits: maxdecimals,
                    style: 'currency',
                    currency
                }).format(value)
            },
            date(value, format = 'date') {
                const options = {};
                if(format.includes('short')) {
                    options.day = '2-digit'
                    options.month = '2-digit'
                    options.year = 'numeric'
                }
                if(format.includes('date')) {
                    options.day = '2-digit'
                    options.month = 'long'
                    options.year = 'numeric'
                }
                if(format.includes('time')) {
                    options.hour = '2-digit'
                    options.minute = '2-digit'
                    options.second = '2-digit'
                }
                const locale = store.getters.info.locale || 'en-US'
                return new Intl.DateTimeFormat(locale,options).format(new Date(value));
            },
            moment(value, format = "DD MMM YYYY") {
                if(!value) {
                    return "";
                }
                return moment(value).format(format);
            }
        }
    }
}
