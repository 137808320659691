<template>
    <div class="empty-layout">
        <router-view :key="$route.fullPath"/>
    </div>
</template>

<script>
import messages from "@/utils/messages";
export default {
    computed: {
        error() {
            return this.$store.getters.error;
        },
    },
    methods: {

    },
    watch: {
        error(error) {
            this.errorHandler(error)
        }
    },
};
</script>
<style lang="scss" scoped>
@import "../assets/ui/_custom-vars.scss";

.empty-layout {
    background-color: $white;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
</style>
