<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="512px" height="512px" viewBox="0 0 512 512">
    <path d="M64 256L224 96 256 128 172 212 144 236 208 232 448 232 448 280 208 280 144 276 172 300 256 384 224 416 64 256Z" />
  </svg>
</template>

<script>
export default {
    name: "IconArrowBack",
  }
</script>